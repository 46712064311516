.animated-circular-loader {
  --size: 15px;
  position: relative;
  width: 60px;
  height: 20px;
  &:before {
    content: '';
    width: var(--size);
    height: var(--size);
    background-color: #0d0d0d;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    inset: 0;
    animation: moveRight 1.2s ease-in-out infinite;
  }
  &:after {
    content: '';
    width: var(--size);
    height: var(--size);
    background-color: #0d0d0d;
    border-radius: 50%;
    position: absolute;
    inset: 0;
    margin: auto;
    animation: moveLeft 1.2s ease-in-out infinite;
  }

  &.complete {
    &:after {
      background-color: #6f3dff;
    }
  }

  @keyframes moveRight {
    0%,
    100% {
      transform: translateX(-15px);
    }
    50% {
      transform: translateX(15px);
    }
  }

  @keyframes moveLeft {
    0%,
    100% {
      transform: translateX(15px);
    }
    50% {
      transform: translateX(-15px);
    }
  }
}
