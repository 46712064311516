@import '_ellipsis';
@import '_circularSpinner';
@import '_circleLoader';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  :root {
    --navbar-height: 56px;
  }

  .modal-content {
    max-height: calc(100vh - env(safe-area-inset-bottom));
    max-height: 100dvh;
  }

  /* use dvh with a vh fallback for firefox etc*/
  .h-dynamic-screen {
    height: 100vh;
    height: 100dvh;
  }
  .h-dynamic-screen-minus-navbar {
    height: calc(100vh - var(--navbar-height));
    height: calc(100dvh - var(--navbar-height));
  }
}

input[type='number'].no-number-controls::-webkit-inner-spin-button,
input[type='number'].no-number-controls::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  overflow-x: hidden;
  height: unset;
  min-height: 100%;
}

.no-number-controls {
  -moz-appearance: textfield;
}
