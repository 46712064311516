.animated-circular-spinner {
  width: 56px;
  height: 56px;
  position: relative;
  background-image: radial-gradient(
      circle 6px at center,
      black 100%,
      transparent 0
    ),
    radial-gradient(circle 6px at center, black 100%, transparent 0),
    radial-gradient(circle 6px at center, black 100%, transparent 0),
    radial-gradient(circle 6px at center, black 100%, transparent 0);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position:
    left top,
    left bottom,
    right top,
    right bottom;
  animation: animated-circular-spinner 1.1s linear infinite;
}

@keyframes animated-circular-spinner {
  0% {
    width: 50px;
    height: 50px;
    transform: rotate(0deg);
  }
  50% {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
  }
  100% {
    width: 50px;
    height: 50px;
    transform: rotate(360deg);
  }
}
