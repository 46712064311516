.animated-ellipsis:after {
  content: '...';
  overflow: hidden;
  display: inline-block;
  text-align: left;
  vertical-align: bottom;
  animation: animated-ellipsis 1s infinite 0.3s;
  animation-fill-mode: forwards;
  width: 1.25em;
}

@keyframes animated-ellipsis {
  25% {
    content: '';
  }
  50% {
    content: '.';
  }
  75% {
    content: '..';
  }
  100% {
    content: '...';
  }
}
